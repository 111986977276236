import React from "react"
import Layout from "../components/layout"
import logo from "../img/logo.svg"
import graphics from "../img/graphics.svg"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Typography, Box, Grid, Button, Paper } from "@material-ui/core"
import { Timer, Star, Notifications } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  hero: {
    minHeight: "100vh",
    background: "linear-gradient(45deg, #FB4F47 30%, #FB4F47 90%)",
    color: "white",
  },
  logo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  graphics: {
    maxWidth: "100%",
    height: "auto",
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    "& span": {
      color: "#FFD700",
    },
  },
  featureCard: {
    padding: theme.spacing(3),
    height: "100%",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  icon: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
    color: "#FB4F47",
  },
}))

export default function Home() {
  const classes = useStyles()

  return (
    <Layout>
      <Box className={classes.hero}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={classes.logo}>
              <Box display="flex" justifyContent="center">
                <img src={logo} alt="Logo" width="217.52" height="90" />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h1" className={classes.title} align="center">
                வணக்கம், <span>தமிழ் கோட் கேம்ப்</span>க்கு தங்களை அன்புடன் வரவேற்கிறோம்
              </Typography>
              <Box display="flex" justifyContent="center" mb={4}>
                <Button variant="contained" size="large" style={{ 
                  background: "#FFD700",
                  color: "#000",
                  fontWeight: "bold",
                  padding: "12px 32px",
                }}>
                  Coming Soon
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <img src={graphics} alt="Graphics" className={classes.graphics} />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} className={classes.featureCard}>
                    <Box textAlign="center">
                      <Timer className={classes.icon} />
                      <Typography variant="h6" gutterBottom>
                        விரைவில் வருகிறது
                      </Typography>
                      <Typography>
                        புதிய அம்சங்களுடன் விரைவில் வெளியாகவுள்ளது
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} className={classes.featureCard}>
                    <Box textAlign="center">
                      <Star className={classes.icon} />
                      <Typography variant="h6" gutterBottom>
                        பிரீமியம் உள்ளடக்கம்
                      </Typography>
                      <Typography>
                        தரமான கற்றல் அனுபவம்
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} className={classes.featureCard}>
                    <Box textAlign="center">
                      <Notifications className={classes.icon} />
                      <Typography variant="h6" gutterBottom>
                        அறிவிப்புகள்
                      </Typography>
                      <Typography>
                        தொடக்க அறிவிப்புகளைப் பெற பதிவு செய்யுங்கள்
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}
